<template>
    <div class="pc-container">
        <div class="main">
            <div class="tab-list">
                <div v-for="(item,index) in list" 
                :key="index" 
                :class="['tab-item', curIndex == index ? 'active' : '']" 
                @click="changeCurIndex(index)">
                    {{item.title}}
                </div>
            </div>
            <div class="cur-info">
                <div class="title">{{curItem.title}}</div>
                <div class="time">{{curItem.publish_at}}</div>
                <div class="divide-line"></div>
                <div v-html="curItem.content" class="content"></div>
            </div>
        </div>
        <!-- <div class="pagination-box">
            <el-pagination @current-change="handleCurrentChange"
                :current-page="currentPage" :pager-count="11"
                :page-sizes="[pageSize, pageSize * 2, pageSize * 3]"
                :page-size="pageSize" layout="total, prev, pager, next, jumper"
                :total="total" background>
            </el-pagination>
        </div> -->
    </div>
</template>

<script>
    export default {
        data() {
            return {
                list: [],
                curIndex: 0,
                currentPage: 1,
                pageSize: 1,
                total: 1
            }
        },
        created() {
            this.getList()
        },
        computed: {
            curItem() {
                return this.list[this.curIndex] || {}
            }
        },
        methods: {
            changeCurIndex(index) {
                this.curIndex = index
            },
            
            getList() {
                this.$api.announcement({
                    withContent: true
                },300000).then(res => {
                    this.list = res.data
                })
            },
            
            handleCurrentChange(val) {
                // console.log(`当前页: ${val}`)
                this.currentPage = val
            }
        }
    }
</script>

<style lang="scss" scoped>
    .pc-container {
        width: 100%;
        box-sizing: border-box;
        padding: 50px $box-padding;
        background-color: #f6f6f6;
        
        .main {
            display: flex;
            width: 100%;
            
            .tab-list {
                min-width: 240px;
                max-width: 240px;
                height: 480px;
                margin-right: 50px;
                overflow: auto;
                
                .tab-item {
                    width: 100%;
                    height: 60px;
                    line-height: 60px;
                    box-sizing: border-box;
                    padding: 0 16px;
                    font-size: 15px;
                    color: #000000;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    background-color: #FFFFFF;
                    cursor: pointer;
                }
                
                .active {
                    color: #FFFFFF;
                    background-color: #5466EF;
                }
            }
            
            .cur-info {
                width: 100%;
                box-sizing: border-box;
                padding: 40px;
                border-radius: 2px;
                background-color: #FFFFFF;
                
                .title {
                    width: 100%;
                    margin-bottom: 20px;
                    font-size: 20px;
                    font-weight: bold;
                    color: #000000;
                    text-align: center;
                    word-wrap: break-word;
                }
                
                .time {
                    width: 100%;
                    margin-bottom: 20px;
                    font-size: 15px;
                    color: #6D6F7E;
                    text-align: right;
                    word-wrap: break-word;
                }
                
                .divide-line {
                    width: 100%;
                    height: 1px;
                    background-color: #E9E9E9;
                }
                
                .content {
                    width: 100%;
                    box-sizing: border-box;
                    padding: 25px 0;
                    word-wrap: break-word;
                }
            }
        }
        
        .pagination-box {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            box-sizing: border-box;
            padding: 50px 0 0 0;
            
            /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
                background-color: #5466EF;
            }
            
            /deep/ .el-pager li:not(.active):hover {
                color: #5466EF;
            }
        }
    }
</style>